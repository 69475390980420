import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutMain from '../views/layout/Main.vue'
import auth from '../middleware/auth'
import eMarket from '../middleware/eMarket'
import i18n from "@/i18n";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: LayoutMain,
        meta: {
            permissions: [],
            middleware: [auth],
            pageName: 'SSC',
            title: 'SSC',
        },
        children: [
            {
                path: '/',
                name: 'Dashboard',
                component: () => import('../views/pages/Dashboard'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: '',
                    title: '',
                },
            },

            /**
             * Warehouse
             */
            {
                path: '/goods-receipt/create',
                name: 'GoodsReceiptCreate',
                component: () => import('../views/pages/goods_receipt/Create'),
                meta: {
                    permissions: ['goods_receipt_create'],
                    middleware: [auth],
                    pageName: i18n.t('labels.receipt_create'),
                    title: i18n.t('labels.receipt_create'),
                },
            },
            /* {
                path: '/goods-receipt/history',
                name: 'GoodsReceiptHistory',
                component: () => import('../views/pages/goods_receipt/History'),
                meta: {
                    permissions: ['goods_receipt_history'],
                    middleware: [auth],
                    pageName: 'Lịch sử nhập kho',
                    title: 'Lịch sử nhập kho',
                },
            }, */
            {
                path: '/goods-return/history',
                name: 'GoodsReturnHistory',
                component: () => import('../views/pages/goods_return/History'),
                meta: {
                    permissions: ['goods_return_history'],
                    middleware: [auth],
                    pageName: i18n.t('labels.return_history'),
                    title: i18n.t('labels.return_history'),
                },
            },
            {
                path: '/goods/list',
                name: 'GoodsList',
                component: () => import('../views/pages/goods/Index'),
                meta: {
                    permissions: ['goods_manager'],
                    middleware: [auth],
                    pageName: i18n.t('labels.goods_list'),
                    title: i18n.t('labels.goods_list'),
                },
            },
            {
                path: '/goods/combo',
                name: 'GoodsCombo',
                component: () => import('../views/pages/goods/Combo'),
                meta: {
                    permissions: ['goods_manager'],
                    middleware: [auth],
                    pageName: i18n.t('labels.combo_list'),
                    title: i18n.t('labels.combo_list'),
                },
            },
            {
                path: '/goods/transfer',
                name: 'GoodsTransfer',
                component: () => import('../views/pages/goods/Transfer'),
                meta: {
                    permissions: ['goods_transfer'],
                    middleware: [auth],
                    pageName: i18n.t('labels.goods_transfer'),
                    title: i18n.t('labels.goods_transfer'),
                },
            },
            {
                path: '/orders/list',
                name: 'OrderList',
                component: () => import('../views/pages/orders/Index'),
                meta: {
                    permissions: ['order_manager'],
                    middleware: [auth],
                    pageName: i18n.t('labels.order_list'),
                    title: i18n.t('labels.order_list'),
                },
            },
            {
                path: '/orders/detail',
                name: 'OrderDetail',
                component: () => import('../views/pages/orders/Detail'),
                meta: {
                    permissions: ['order_manager'],
                    middleware: [auth],
                    pageName: i18n.t('labels.order_detail'),
                    title: i18n.t('labels.order_detail'),
                },
            },
            {
                path: '/orders/report',
                name: 'OrderReport',
                component: () => import('../views/pages/orders/Dashboard'),
                meta: {
                    permissions: ['order_dashboard_manager'],
                    middleware: [auth],
                    pageName: i18n.t('labels.fast_report'),
                    title: i18n.t('labels.fast_report'),
                },
            },
            {
                path: '/orders/create-excel',
                name: 'OrderCreateExcel',
                component: () => import('../views/pages/orders/CreateExcel'),
                meta: {
                    permissions: ['order_create_excel'],
                    middleware: [auth],
                    pageName: i18n.t('labels.create_order_excel'),
                    title: i18n.t('labels.create_order_excel'),
                },
            },
            {
                path: '/orders/create-e-market',
                name: 'OrderCreateEMarket',
                component: () => import('../views/pages/orders/CreateEMarket'),
                meta: {
                    permissions: ['order_create_e_market'],
                    middleware: [auth],
                    pageName: i18n.t('labels.create_order_e_market'),
                    title: i18n.t('labels.create_order_e_market'),
                },
            },
            {
                path: '/orders/create-single',
                name: 'OrderCreateSingle',
                component: () => import('../views/pages/orders/CreateSingleNew'),
                meta: {
                    permissions: ['order_create_single'],
                    middleware: [auth],
                    pageName: i18n.t('labels.create_order_single'),
                    title: i18n.t('labels.create_order_single'),
                },
            },

            /**
             * End Warehouse
             */


            /**
             * Account
             */
            {
                path: '/account',
                name: 'Account',
                component: () => import('../views/pages/account/Index'),
                meta: {
                    permissions: ['customer_account'],
                    middleware: [auth],
                    pageName: i18n.t('labels.account_management'),
                    title: i18n.t('labels.account_management'),
                },
            },
            {
                path: '/pos',
                name: 'Pos',
                component: () => import('../views/pages/pos/Index'),
                meta: {
                    permissions: ['customer_account'],
                    middleware: [auth],
                    pageName: i18n.t('labels.pos_management'),
                    title: i18n.t('labels.pos_management'),
                },
            },
            {
                path: '/account-auth-:eMarket',
                name: 'AccountEMarket',
                component: () => import('../views/pages/account/EMarketAuth.vue'),
                meta: {
                    permissions: ['customer_account'],
                    middleware: [eMarket],
                    pageName: i18n.t('labels.account_confirm'),
                    title: i18n.t('labels.account_confirm'),
                },
            },
            {
                path: '/oauth-zalo',
                name: 'OauthZalo',
                component: () => import('../views/pages/account/OauthZalo.vue'),
                meta: {
                    permissions: ['customer_account'],
                    middleware: [auth],
                    pageName: i18n.t('labels.account_confirm'),
                    title: i18n.t('labels.account_confirm'),
                },
            },
            {
                path: '/change-password',
                name: 'ChangePassword',
                component: () => import('../views/pages/account/ChangePassword.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: i18n.t('labels.change_password'),
                    title: i18n.t('labels.change_password'),
                },
            },
            {
                path: '/delivery-shop',
                name: 'DeliveryShopConfig',
                component: () => import('../views/pages/delivery_shop/Index.vue'),
                meta: {
                    permissions: [
                        "administration_delivery_shop"
                    ],
                    middleware: [auth],
                    pageName: i18n.t('labels.delivery_shop'),
                    title: i18n.t('labels.delivery_shop'),
                },
            },
            /**
             * End Account
             */


            /**
             * POS
             */
            {
                path: '/pos/goods-list',
                name: 'PosGoodsList',
                component: () => import('../views/pages/pos_goods/Index'),
                meta: {
                    permissions: ['pos_goods_manager'],
                    middleware: [auth],
                    pageName: i18n.t('labels.goods_list'),
                    title: i18n.t('labels.goods_list'),
                },
            },
            {
                path: '/pos/goods-transfer',
                name: 'PosGoodsTransfer',
                component: () => import('../views/pages/pos_goods/Transfer'),
                meta: {
                    permissions: ['pos_goods_transfer'],
                    middleware: [auth],
                    pageName: i18n.t('labels.goods_transfer'),
                    title: i18n.t('labels.goods_transfer'),
                },
            },
            {
                path: '/pos/goods-receipt-create',
                name: 'PosGoodsReceiptCreate',
                component: () => import('../views/pages/pos_goods/ReceiptCreate'),
                meta: {
                    permissions: ['pos_goods_receipt_create'],
                    middleware: [auth],
                    pageName: i18n.t('labels.pos_goods_receipt'),
                    title: i18n.t('labels.pos_goods_receipt'),
                },
            },
            /* {
                path: '/pos/goods-receipt-history',
                name: 'PosGoodsReceiptHistory',
                component: () => import('../views/pages/pos_goods/ReceiptHistory'),
                meta: {
                    permissions: [ 'pos_goods_receipt_history'],
                    middleware: [auth],
                    pageName: 'Lịch sử nhập POS',
                    title: 'Lịch sử nhập POS',
                },
            }, */
            {
                path: '/pos/order-list',
                name: 'PosOrderList',
                component: () => import('../views/pages/pos/OrderList.vue'),
                meta: {
                    permissions: ['pos_order_list'],
                    middleware: [auth],
                    pageName: i18n.t('labels.pos_order_report'),
                    title: i18n.t('labels.pos_order_report'),
                },
            },
            {
                path: '/pos/order-online-list',
                name: 'PosOrderOnlineList',
                component: () => import('../views/pages/order_online/Index.vue'),
                meta: {
                    permissions: ['pos_order_online_list'],
                    middleware: [auth],
                    pageName: i18n.t('labels.order_online_list'),
                    title: i18n.t('labels.order_online_list'),
                },
            },
            {
                path: '/pos/order-online-create',
                name: 'PosOrderCreateSingle',
                component: () => import('../views/pages/order_online/CreateSingle'),
                meta: {
                    permissions: ['pos_order_create_single'],
                    middleware: [auth],
                    pageName: i18n.t('labels.create_order_online'),
                    title: i18n.t('labels.create_order_online'),
                },
            },
            /**
             * End POS
             */


            /**
             * administration
             */
            {
                path: '/administration/receipt-history',
                name: 'AdministrationReceiptHistory',
                component: () => import('../views/pages/goods_receipt/History'),
                meta: {
                    permissions: ['administration_receipt_history'],
                    middleware: [auth],
                    pageName: i18n.t('labels.warehouse_receipt_history'),
                    title: i18n.t('labels.warehouse_receipt_history'),
                },
            },
            {
                path: '/administration/report',
                name: 'AdministrationReportList',
                component: () => import('../views/pages/administration/ReportList.vue'),
                meta: {
                    permissions: ['administration_report_revenue'],
                    middleware: [auth],
                    pageName: i18n.t('labels.business_results'),
                    title: i18n.t('labels.business_results'),
                },
            },
            {
                path: '/administration/goods-price',
                name: 'AdministrationGoodsPrice',
                component: () => import('../views/pages/administration/GoodsPrice.vue'),
                meta: {
                    permissions: ['administration_goods_price'],
                    middleware: [auth],
                    pageName: i18n.t('labels.sale_price'),
                    title: i18n.t('labels.sale_price'),
                },
            },
            {
                path: '/administration/goods-cost',
                name: 'AdministrationGoodsCost',
                component: () => import('../views/pages/administration/GoodsCost.vue'),
                meta: {
                    permissions: ['administration_goods_cost'],
                    middleware: [auth],
                    pageName: i18n.t('labels.cost_price'),
                    title: i18n.t('labels.cost_price'),
                },
            },
            {
                path: '/administration/supplier',
                name: 'AdministrationSupplier',
                component: () => import('../views/pages/administration/Supplier.vue'),
                meta: {
                    permissions: ['administration_supplier'],
                    middleware: [auth],
                    pageName: i18n.t('labels.supplier'),
                    title: i18n.t('labels.supplier'),
                },
            },
            {
                path: '/administration/supply',
                name: 'AdministrationSupply',
                component: () => import('../views/pages/administration/Supplies.vue'),
                meta: {
                    permissions: ['administration_supply'],
                    middleware: [auth],
                    pageName: i18n.t('labels.supplies'),
                    title: i18n.t('labels.supplies'),
                },
            },
            {
                path: '/administration/goods-report',
                name: 'AdministrationGoodsReport',
                component: () => import('../views/pages/goods/Report'),
                meta: {
                    permissions: ['administration_goods_report'],
                    middleware: [auth],
                    pageName: i18n.t('labels.delivery_speed'),
                    title: i18n.t('labels.delivery_speed'),
                },
            },
            {
                path: '/administration/goods-category',
                name: 'AdministrationGoodsCategory',
                component: () => import('../views/pages/goods/Category'),
                meta: {
                    permissions: ['administration_goods_category'],
                    middleware: [auth],
                    pageName: i18n.t('labels.category'),
                    title: i18n.t('labels.category'),
                },
            },
            {
                path: '/administration/goods-profile',
                name: 'AdministrationGoodsProfile',
                component: () => import('../views/pages/goods/Profile'),
                meta: {
                    permissions: ['administration_goods_profile'],
                    middleware: [auth],
                    pageName: i18n.t('labels.profile'),
                    title: i18n.t('labels.profile'),
                },
            },
            {
                path: '/administration/goods-missing',
                name: 'AdministrationGoodsMissing',
                component: () => import('../views/pages/goods/Missing'),
                meta: {
                    permissions: ['administration_goods_missing'],
                    middleware: [auth],
                    pageName: i18n.t('labels.goods_missing'),
                    title: i18n.t('labels.goods_missing'),
                },
            },
            {
                path: '/administration/pos-goods-display-request',
                name: 'AdministrationPosGoodsDisplayRequest',
                component: () => import('../views/pages/pos_goods/DisplayRequest.vue'),
                meta: {
                    permissions: ['administration_pos_goods_display_request'],
                    middleware: [auth],
                    pageName: i18n.t('labels.pos_goods_display_request'),
                    title: i18n.t('labels.pos_goods_display_request'),
                },
            },
            {
                path: '/administration/pos-promotion-config',
                name: 'AdministrationPosPromotionConfig',
                component: () => import('../views/pages/pos/PromotionConfig.vue'),
                meta: {
                    permissions: ['administration_pos_promotion_config'],
                    middleware: [auth],
                    pageName: i18n.t('labels.pos_promotion'),
                    title: i18n.t('labels.pos_promotion'),
                },
            },
            {
                path: '/administration/pos-accrue-point-config',
                name: 'AdministrationPosAccruePointConfig',
                component: () => import('../views/pages/pos/AccruePointConfig.vue'),
                meta: {
                    permissions: ['administration_pos_accrue_point_config'],
                    middleware: [auth],
                    pageName: i18n.t('labels.pos_accrue_point'),
                    title: i18n.t('labels.pos_accrue_point'),
                },
            },
            {
                path: '/administration/pos-voucher-config',
                name: 'AdministrationPosVoucherConfig',
                component: () => import('../views/pages/pos/VoucherConfig.vue'),
                meta: {
                    permissions: ['administration_pos_voucher_config'],
                    middleware: [auth],
                    pageName: i18n.t('labels.pos_voucher'),
                    title: i18n.t('labels.pos_voucher'),
                },
            },
            {
                path: '/internal/request',
                name: 'InternalRequest',
                component: () => import('../views/pages/internal_requests/Index'),
                meta: {
                    permissions: ['administration_request'],
                    middleware: [auth],
                    pageName: i18n.t('labels.order_request'),
                    title: i18n.t('labels.order_request'),
                },
            },
            {
                path: '/administration/zns',
                name: 'AdministrationZns',
                component: () => import('../views/pages/zns/Index'),
                meta: {
                    permissions: ['administration_zns'],
                    middleware: [auth],
                    pageName: i18n.t('labels.zns_history'),
                    title: i18n.t('labels.zns_history'),
                },
            },
            {
                path: '/administration/goods-debt',
                name: 'AdministrationGoodsDebt',
                component: () => import('../views/pages/goods/Debt'),
                meta: {
                    permissions: ['administration_goods_debt'],
                    middleware: [auth],
                    pageName: i18n.t('labels.goods_debt'),
                    title: i18n.t('labels.goods_debt'),
                },
            },
            {
                path: '/administration/present',
                name: 'AdministrationPresent',
                component: () => import('../views/pages/goods/Present'),
                meta: {
                    permissions: ['administration_present'],
                    middleware: [auth],
                    pageName: i18n.t('labels.present'),
                    title: i18n.t('labels.present'),
                },
            },
            {
                path: '/administration/physical-combo',
                name: 'AdministrationPhysicalCombo',
                component: () => import('../views/pages/goods/PhysicalCombo'),
                meta: {
                    permissions: ['administration_physical_combo'],
                    middleware: [auth],
                    pageName: i18n.t('labels.physical_combo_program'),
                    title: i18n.t('labels.physical_combo_program'),
                },
            },
            {
                path: '/administration/priority-goods-quantity',
                name: 'AdministrationPriorityGoodsQuantity',
                component: () => import('../views/pages/goods/PriorityGoodsQuantity.vue'),
                meta: {
                    permissions: ['administration_priority_goods_quantity'],
                    middleware: [auth],
                    pageName: i18n.t('labels.priority_goods_quantity'),
                    title: i18n.t('labels.priority_goods_quantity'),
                },
            },
            {
                path: '/timekeeping/check',
                name: 'OfficeTimekeepingCheck',
                component: () => import('../views/pages/timekeeping/Check'),
                meta: {
                    permissions: ['office_timekeeping'],
                    middleware: [auth],
                    pageName: i18n.t('labels.timekeeping'),
                    title: i18n.t('labels.timekeeping'),
                },
            },
            {
                path: '/timekeeping/list',
                name: 'OfficeTimekeepingList',
                component: () => import('../views/pages/timekeeping/List'),
                meta: {
                    permissions: ['timekeeping_manager'],
                    middleware: [auth],
                    pageName: i18n.t('labels.timekeeping'),
                    title: i18n.t('labels.timekeeping'),
                },
            },
            /**
             * End administration
             */

            /**
             * goods Production
             */
            {
                path: '/material/receipt-create',
                name: 'MaterialReceiptCreate',
                component: () => import('../views/pages/material_receipt/Create'),
                meta: {
                    permissions: ['material_receipt_create'],
                    middleware: [auth],
                    pageName: i18n.t('labels.material_receipt'),
                    title: i18n.t('labels.material_receipt'),
                },
            },
            {
                path: '/material/list',
                name: 'MaterialList',
                component: () => import('../views/pages/material/Index'),
                meta: {
                    permissions: ['material_manager'],
                    middleware: [auth],
                    pageName: i18n.t('labels.material_list'),
                    title: i18n.t('labels.material_list'),
                },
            },
            {
                path: '/material/receipt-history',
                name: 'MaterialReceiptHistory',
                component: () => import('../views/pages/material_receipt/History'),
                meta: {
                    permissions: ['material_receipt_history'],
                    middleware: [auth],
                    pageName: i18n.t('labels.receipt_history'),
                    title: i18n.t('labels.receipt_history'),
                },
            },
            {
                path: '/goods-production/record',
                name: 'GoodsProductionRecord',
                component: () => import('../views/pages/goods_production/Record'),
                meta: {
                    permissions: ['goods_production_record'],
                    middleware: [auth],
                    pageName: i18n.t('labels.product_profile'),
                    title: i18n.t('labels.product_profile'),
                },
            },
            {
                path: '/goods-production/requirement',
                name: 'GoodsProductionRequirement',
                component: () => import('../views/pages/goods_production/Requirement'),
                meta: {
                    permissions: ['goods_production_requirement'],
                    middleware: [auth],
                    pageName: i18n.t('labels.production_request'),
                    title: i18n.t('labels.production_request'),
                },
            },
            {
                path: '/goods-production/quantitative',
                name: 'GoodsProductionQuantitative',
                component: () => import('../views/pages/goods_production/Quantitative'),
                meta: {
                    permissions: ['goods_production_quantitative'],
                    middleware: [auth],
                    pageName: i18n.t('labels.quantitative_standard'),
                    title: i18n.t('labels.quantitative_standard'),
                },
            },
            /**
             * End goods Production
             */
            /**
             * NSCA
             */
            {
                path: '/nsca/stamp',
                name: 'NscaStamp',
                component: () => import('../views/pages/nsca/Stamp.vue'),
                meta: {
                    permissions: ['nsca_stamp'],
                    middleware: [auth],
                    pageName: 'NSCA In tem',
                    title: 'NSCA In tem',
                },
            },

            {
                path: '/employee-warning',
                name: 'EmployeeWarning',
                component: () => import('../views/pages/employee/Warning.vue'),
                meta: {
                    permissions: ['warehouse_employee_warning'],
                    middleware: [auth],
                    pageName: i18n.t('labels.employee_warning'),
                    title: i18n.t('labels.employee_warning'),
                },
            },
            /**
             * End NSCA
             */

            /**
             * Hoa don
             */
            {
                path: '/invoice',
                name: 'Invoice',
                component: () => import('../views/pages/invoice/Index'),
                meta: {
                    permissions: ['administration_invoice'],
                    middleware: [auth],
                    pageName: i18n.t('labels.invoice'),
                    title: i18n.t('labels.invoice'),
                },
            },
            {
                path: '/only-invoice',
                name: 'OnlyInvoice',
                component: () => import('../views/pages/invoice/Only'),
                meta: {
                    permissions: ['administration_only_invoice'],
                    middleware: [auth],
                    pageName: i18n.t('labels.invoice'),
                    title: i18n.t('labels.invoice'),
                },
            },
        ]
    },
    {
      path: '/v2*',
      redirect: '/'
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/pages/Register.vue'),
    },
    {
        path: '/customer-register',
        name: 'CustomerRegister',
        component: () => import('../views/pages/CustomerRegister.vue'),
        meta: {
            permissions: [],
            pageName: i18n.t('labels.register'),
            title: i18n.t('labels.register'),
        },
    },
    {
        path: '/services',
        name: 'Service',
        component: () => import('../views/pages/Service.vue'),
        meta: {
            permissions: [],
            pageName: i18n.t('labels.terms_of_use'),
            title: i18n.t('labels.terms_of_use_ssc'),
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/pages/Login.vue'),
    },
    {
        path: '/403',
        name: 'Error403',
        component: () => import('../views/pages/errors/403.vue'),
    }
]


const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
})

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || process.env.VUE_APP_TITLE;
    });
});

export default router
