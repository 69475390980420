<template>
  <v-autocomplete
    class="c-input-small"
    :value="value"
    :items="filteredOptions"
    :label="label"
    :disabled="disabled"
    :class="customClass"
    :outlined="outlined"
    :dense="dense"
    :hide-details="hideDetails"
    :single-line="singleLine"
    :clearable="clearable"
    @input="updateValue"
  ></v-autocomplete>
</template>

<script>
import { httpClient } from "@/libs/http";
export default {
  name: "SelectTaxCodeModel",
  props: {
    value: {
      type: [Number, String, Array, Object],
      default: () => null,
    },
    label: {
      type: String,
      default: () => "Tax Code",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    dense: {
      type: Boolean,
      default: () => true,
    },
    hideDetails: {
      type: Boolean,
      default: () => true,
    },
    singleLine: {
      type: Boolean,
      default: () => true,
    },
    clearable: {
      type: Boolean,
      default: () => false,
    },
    customClass: {
      type: String,
      default: () => null,
    },
  },
  data: () => ({
    options: [],
  }),
  model: {
    prop: ["value"],
    event: "change",
  },
  computed: {
    filteredOptions() {
      return this.options;
    },
  },
  watch: {},
  mounted() {
    this.getList();
  },
  methods: {
    updateValue(val) {
      this.$emit("change", val);
    },
    getList() {
      httpClient
        .post("/invoice-get-tax-code", { status: 1 })
        .then(({ data }) => {
          this.options = [...data.rows].map((p) => ({
            value: p.id,
            text: p.code || "",
          }));
        });
    },
  },
};
</script>

<style scoped></style>
