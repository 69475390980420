<template>
  <v-autocomplete
    class="c-input-small"
    multiple
    :items="options"
    :value="value"
    :label="label"
    :disabled="disabled"
    :class="customClass"
    :outlined="outlined"
    :dense="dense"
    :hide-details="hideDetails"
    :single-line="singleLine"
    :clearable="clearable"
    @input="updateValue"
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon :color="value.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("labels.all") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>
    <template v-slot:selection="{ item, index }">
      <template>
        <template v-if="likesAllOption">
          <span
            v-if="index === 0"
            class="font-weight-medium text-caption"
            :class="{ 'black--text': !disabled }"
          >
            {{ $t("labels.all") }}
          </span>
        </template>
        <template v-else>
          <span
            v-if="index === 0"
            class="font-weight-medium text-caption"
            :class="{ 'black--text': !disabled }"
          >
            {{ item.text }}
          </span>
          <v-chip v-if="index === 1" small>
            <span>+{{ value.length - 1 }}</span>
          </v-chip>
        </template>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "SelectMultipleModel",
  props: {
    value: {
      type: [Number, String, Array, Object],
      default: () => null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: () => "",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    dense: {
      type: Boolean,
      default: () => true,
    },
    hideDetails: {
      type: Boolean,
      default: () => true,
    },
    singleLine: {
      type: Boolean,
      default: () => true,
    },
    clearable: {
      type: Boolean,
      default: () => false,
    },
    customClass: {
      type: String,
      default: () => null,
    },
  },
  data: () => ({}),
  model: {
    prop: ["value"],
    event: "change",
  },
  computed: {
    likesAllOption() {
      return this.value.length === this.options.length;
    },
    likesSomeOption() {
      return this.value.length > 0 && !this.likesAllOption;
    },
    icon() {
      if (this.likesAllOption) return "mdi-close-box";
      if (this.likesSomeOption) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllOption) {
          this.updateValue([]);
        } else {
          const value = this.options.map((o) => o.value);
          this.updateValue(value);
        }
      });
    },
    updateValue(val) {
      this.$emit("change", val);
    },
  },
};
</script>
